<template>
	<div
		:id="'dropdown-' + id"
		:class="'btn-group ' + groupClasses"
		role="group"
	>
		<button
			type="button"
			:class="`btn btn-${color} no-focus ` + classes"
			@click="onClick"
			:data-toggle="dropdownItems.length ? 'dropdown' : ''"
			:aria-haspopup="!!dropdownItems.length"
			aria-expanded="false"
			:title="btnTitle"
		>
			<slot>
				{{ text }}
			</slot>
		</button>
		<div
			:class="'dropdown-menu ' + dropdownClasses"
			v-if="dropdownItems.length"
		>
			<template v-for="(item, index) in dropdownItems">
				<div
					v-if="item === 'hr'"
					:key="index"
					class="dropdown-divider"
				></div>
				<router-link
					v-else-if="item.link"
					:key="index"
					:class="
						'dropdown-item ' +
						(index === currentItem ? 'active ' : '') +
						(item.classes || '')
					"
					:to="item.link"
				>
					<template v-if="item.prepend">
						<div class="d-flex">
							<div v-if="item.prepend">
								<img
									v-if="item.prepend.type === 'img'"
									:src="item.prepend.src"
									alt="icon"
									:class="'mr-2 ' + item.prepend.classes"
								/>
							</div>
							{{ item.text || item }}
							<div v-if="item.append">
								<img
									v-if="item.append.type === 'img'"
									:src="item.append.src"
									alt="icon"
									:class="'mr-2 ' + item.append.classes"
								/>
							</div>
						</div>
					</template>
					<template v-else>
						{{ item.text || item }}
						<p v-if="item && item.subText" class="text-muted m-0">
							<small>{{ item.subText }}</small>
						</p>
					</template>
				</router-link>
				<button
					v-else
					:key="index"
					:class="
						'dropdown-item ' +
						(index === currentItem ? 'active ' : '') +
						(item.classes || '')
					"
					@click="clickChange(index)"
				>
					<template v-if="item.prepend">
						<div class="d-flex">
							<div v-if="item.prepend">
								<img
									v-if="item.prepend.type === 'img'"
									:src="item.prepend.src"
									alt="icon"
									:class="'mr-2 ' + item.prepend.classes"
								/>
							</div>
							{{ item.text || item }}
							<div v-if="item.append">
								<img
									v-if="item.append.type === 'img'"
									:src="item.append.src"
									alt="icon"
									:class="'mr-2 ' + item.append.classes"
								/>
							</div>
						</div>
					</template>
					<template v-else>
						{{ item.text || item }}
						<p v-if="item && item.subText" class="color-dark-gray m-0">
							<small>{{ item.subText }}</small>
						</p>
					</template>
				</button>
			</template>
		</div>
	</div>
</template>

<script>
	export default {
		name: "buttonGroup",
		props: {
			id: {
				type: String,
				default: "",
			},
			groupClasses: {
				type: String,
				default: "", // btn-group-lg, btn-group-sm
			},
			color: {
				type: String,
				default: "main",
			},
			classes: {
				type: String,
				default: "",
			},
			btnTitle: {
				type: String,
				default: "",
			},
			text: {
				type: String,
				default: "",
			},
			dropdownClasses: {
				type: String,
				default: "",
			},
			dropdownItemsDefalut: {
				type: Number,
				default: -1,
			},
			dropdownItems: {
				type: Array,
				default: () => [],
			},
			onClick: {
				type: Function,
				default: () => {},
			},
			onChange: {
				type: Function,
				default: () => {},
			},
			onCloseCallback: {
				type: Function,
				default: () => {},
			},
		},
		data() {
			return {
				currentItem: this.dropdownItemsDefalut,
			};
		},
		methods: {
			clickChange(index) {
				this.currentItem = index;
				this.onChange(this.dropdownItems[index]);
			},
		},
		watch: {
			dropdownItemsDefalut: function (newVal) {
				this.currentItem = newVal;
			},
		},
		mounted() {
			if (this.id) {
				const _this = this;
				$(`#dropdown-${this.id}`).on("hide.bs.dropdown", function () {
					_this.onCloseCallback();
				});
			}
		},
	};
</script>
