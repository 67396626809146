import Vue from "vue";
import router from "@/router";
import i18n from "@/i18n";
import App from "@/App.vue";

// https://www.npmjs.com/package/skeleton-loader-vue
import VueSkeletonLoader from "skeleton-loader-vue";

import "bootstrap"; // Importing JavaScript
// Import plugins individually as needed:
// import 'bootstrap/js/dist/util';
// import 'bootstrap/js/dist/dropdown';

// Register the component globally
Vue.component("vue-skeleton-loader", VueSkeletonLoader);

new Vue({
  el: "#app",
  router,
  i18n,
  components: { App },
  template: "<App/>"
});
