<template>
	<div class="d-flex bg-white mt-4" style="height: 650px">
		<div class="sidenav">
			<a
				v-for="(i, idx) in sidenavList"
				:key="i.navText"
				href="javascript:void(0);"
				:class="idx === currentNavPage ? 'active' : ''"
				@click="changeNavPage(idx)"
			>
				{{ i.navText }}
			</a>
		</div>
		<div class="page-content overflow-auto px-5 py-4">
			<h5 class="color-dark-gray font-weight-bold">{{ title }}</h5>
			<template v-if="Array.isArray(desc)">
				<ul class="list-style1">
					<li v-for="i in desc" :key="i" v-html="i">{{ i }}</li>
				</ul>
			</template>
			<img :src="imgUrl" :alt="imgAlt" />
		</div>
	</div>
</template>

<script>
	export default {
		name: "shared-advantage",
		data() {
			return {
				sidenavList: [
					{
						navText: "网络锁",
						title: "网络锁：",
						desc: [
							"网络锁是什么？<br/>网络锁开关将在 GT NET 点开链接或被禁用时自动阻止所用互联网访问，以防止暴露您的真实IP，可确保您的个人信息安全。什么可能导致 GT NET 断开链接：<br/>1、电脑上安装了安全软件（如杀毒软件、防火墙等），则可能导致VPN链接中断。<br/>2、如果您的互联网不稳定，您的VPN链接将会丢失。<br/>3、如果您使用公共Wi-Fi，因为路由器的负载很重，可能导致服务中断。",
						],
					},
					{
						navText: "应用程序分流",
						title: "应用程序分流：",
						desc: [
							'GT NET支持两种应用程序分流模式：全局模式，应用模式。<span class="color-red">*仅限安卓使用</span>',
							"全局模式：所有应用程序都通过vpn访问互联网",
							"应用模式：仅指定的应用程序可通过vpn访问互联网<br/>有些用户可能希望仅将VPN用于某些应用程序，例如：Skype、Messenger、eBanking或torrent客户端，而不是用于浏览网页；其他人可能更喜欢能够通过vpn传输所有内容。特别是在审查制度较高的国家，有些人会注重我们独特的分流功能，仅传输专用国际网站。",
						],
					},
					{
						navText: "信任wifi",
						title: "信任Wi-Fi：",
						desc: [
							"无线上网已成了我们日常生活的一部分。但是使用公共Wi-Fi上网存在着风险，例如，用户的浏览活动很容易被拦截，并且个人敏感信息也很有可能被窃取和盗用。<br/>GT NET可以添加您信任的Wi-Fi名称，在您访问未知的Wi-Fi 网络时自动连接到GT NET，对流量进行了加密，以防止窥探者的侵害，让用户在任何公共Wi-Fi网络上都能保持身份信息的安全。",
						],
					},
					{
						navText: "多平台多终端",
						title: "多平台终端：",
						desc: [
							"GT NET支持四种平台：Windows、IOS、苹果和安卓<br/>每一位GT NET用户，登陆之后，都可以购买单条或者多条vnp专线，每条专线支持多个用户同时使用。<br/>为了确保您有很好的上网体验，我们会限制每条专线的使用人数。例如，专线的带宽是10M，则最多可10台终端同时在线；100M带宽，则最多可100台终端同时在线。",
						],
					},
					{
						navText: "中国优化线路",
						title: "中国优化线路：",
						desc: [
							"GT NET针对海外华人或者海外中国公司提供优化线路，通过我们在香港的物理机房和中国大陆之间的海底光纤，确保回国线路的高效可靠连接，这是其他同类产品所不具备的。",
						],
					},
					{
						navText: "弹性资源调整",
						title: "弹性资源调整：",
						desc: [
							"GT NET为每一位用户提供便捷的管理后台，您可以根据需要随时调整您的vpn专线资源，应对您不断变化的业务需求，您可以通过管理后台实现如下功能：<br/>1、您可以批量购买（续费）线路，例如您有3个部门，销售一部、销售二部、销售三部，那么您可以购买3条10M带宽的vnp专线，每个部门使用1条，像安装宽带一样简单；<br/>2、调整带宽，随着业务的发展，人员的增加，您原先购买的10M带宽会不够使用，您可以通过管理后台随时增加带宽<br/>3、管理子账户，可以随时增减用户，便于集中控制访问权限；    4、设置访问白名单。",
						],
					},
				],
				currentNavPage: 0,
				title: "",
				desc: "",
				imgUrl: "",
				imgAlt: "",
			};
		},
		methods: {
			changeNavPage(idx) {
				this.currentNavPage = idx;

				this.title = this.sidenavList[this.currentNavPage].title;
				this.desc = this.sidenavList[this.currentNavPage].desc;

				let imgUrl = "";
				try {
					imgUrl = require(`@/assets/imgs/exclusive/${
						this.currentNavPage + 1
					}2x.png`);
				} catch (err) {}
				this.imgUrl = imgUrl;
				this.imgAlt = this.sidenavList[this.currentNavPage].title;
			},
		},
	};
</script>

<style scoped>
	/* side nav */
	.sidenav {
		height: 100%;
		/* 100% Full-height */
		width: 250px;
		/* 0 width - change this with JavaScript */
		overflow-x: hidden;
		/* Disable horizontal scroll */
		transition: 0.5s;
		/* 0.5 second transition effect to slide in the sidenav */
		background-color: #1f2b33ff;
	}

	/* The navigation menu links */

	.sidenav a {
		color: #a6a5a5ff;
		text-decoration: none;
		font-size: 16px;
		display: block;
		transition: 0.3s;
		padding: 25px 30px;
	}

	.sidenav a.active,
	.sidenav a:hover {
		color: #ffffff;
		background: rgb(39, 108, 234);
		background: linear-gradient(
			90deg,
			rgba(39, 108, 234, 1) 0%,
			rgba(255, 255, 255, 0) 100%
		);
	}

	.sidenav a.active:hover {
		color: #ffffff;
	}

	.page-content {
		flex: 1;
	}

	.page-content li {
		line-height: 38px;
	}
</style>