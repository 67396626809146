<template>
	<div>
		<div class="container-fluid p-0 server">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-md-6 color-white text-center">
						<h1 class="title">
							{{ $t("server_title") }}
						</h1>
						<a :href="toDashboardLogin" class="btn btn-main banner-btn">
							{{ $t("start_using") }}
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="container section">
			<template v-for="i in locations">
				<h6 :key="i.title" class="loc-title">
					{{ $t("continent." + i.title) }}
				</h6>
				<div :key="i.title + 'locations'" class="row px-5">
					<template v-for="(j, idxJ) in i.child">
						<div
							:key="j.title"
							class="col-auto d-flex loc-box align-items-center"
						>
							<img
								:src="j.imgUrl"
								:alt="$t('countries.' + j.title)"
								style="width: 25px"
							/>
							<p class="mb-0 pl-2">
								<small>{{ $t("countries." + j.title) }}</small>
							</p>
						</div>
						<div :key="'divider' + idxJ" class="loc-divider"></div>
					</template>
				</div>
			</template>
		</div>
		<!--  -->
		<!--  -->
		<benefits />
		<!--  -->
		<free-trial />
		<!--  -->
	</div>
</template>

<script>
	import { keys, localS } from "@/utils";

	export default {
		name: "server",
		components: {
			benefits: () => import("@/components/benefits"),
			freeTrial: () => import("@/components/freetrial"),
		},
		data() {
			let urlQuerys = "";
			if (localS.get(keys.channel)) {
				urlQuerys = "?channel=" + localS.get(keys.channel);
			}
			return {
				toDashboardLogin: process.env.DASHBOARD_URL + urlQuerys,
				locationsFormat: [
					{
						title: "europe",
						child: [
							"bulgaria",
							"germany",
							"ukraine",
							"russia",
							"czech_republic",
							"poland",
							"latvia",
							"spain",
							"denmark",
							"norway",
							"netherlands",
						],
					},
					{
						title: "north_america",
						child: ["america", "canada"],
					},
					{
						title: "asia",
						child: [
							"china_hong_kong",
							"china",
							"japan",
							"indonesia",
							"singapore",
							"thailand",
							"india",
						],
					},
					{
						title: "south_america",
						child: ["mexico", "brazil"],
					},
					{
						title: "oceania",
						child: ["australia"],
					},
				],
			};
		},
		computed: {
			locations() {
				return this.locationsFormat.map((i) => ({
					title: i.title,
					child: i.child.map((j, idxJ) => ({
						imgUrl: require(`@/assets/imgs/countries/${i.title}_${
							idxJ + 1
						}.png`),
						title: j,
					})),
				}));
			},
		},
	};
</script>

<style scoped>
	.server {
		background-image: url(@/assets/imgs/bg-server-location2x.png);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}
	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.server {
			background-image: url(@/assets/imgs/bg-server-mobile-location2x.png);
		}
	}
	/* Small devices (landscape phones, 576px and up) */
	@media (min-width: 576px) and (max-width: 767.98px) {
		.server {
			background-image: url(@/assets/imgs/bg-server-mobile-location2x.png);
		}
	}

	.server > .container {
		padding-top: 200px;
		padding-bottom: 200px;
	}
	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.server > .container {
			padding-top: 60px;
			padding-bottom: 60px;
		}
	}
	/* Small devices (landscape phones, 576px and up) */
	@media (min-width: 576px) and (max-width: 767.98px) {
		.server > .container {
			padding-top: 60px;
			padding-bottom: 60px;
		}
	}

	.banner-btn {
		margin-top: 60px;
	}
	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.banner-btn {
			margin-top: 300px;
		}
	}
	/* Small devices (landscape phones, 576px and up) */
	@media (min-width: 576px) and (max-width: 767.98px) {
		.banner-btn {
			margin-top: 300px;
		}
	}

	.loc-title {
		background-color: #f8f8f8ff;
		padding: 15px 30px;
	}
	.loc-box {
		padding: 20px 0;
		margin-left: 15px;
		margin-right: 15px;
	}
	/* divider background-color: #545c6aff; opacity: 0.1; */
</style>