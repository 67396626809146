export function set(key, value = "") {
  localStorage.setItem(key, value);
}

export function get(key) {
  return localStorage.getItem(key) || "";
}

export function setJSON(key, value = {}) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function getJSON(key, defaultJson = {}) {
  return localStorage.getItem(key)
    ? JSON.parse(localStorage.getItem(key))
    : defaultJson;
}

export function del(key) {
  localStorage.removeItem(key);
}

export function clear(key) {
  localStorage.clear();
}

export function getStoreValue(key, item) {
  const store = localStorage.getItem(key)
    ? JSON.parse(localStorage.getItem(key))
    : {};
  return item in store ? store[item] : "";
}
