<template>
	<div>
		<div class="container-fluid p-0">
			<download-devices
				:pages="sliderPages"
				:defaultActive="defaultActive"
			/>
		</div>
		<!--  -->
		<div class="manual">
			<div class="container section">
				<div class="row justify-content-center">
					<div class="col-md-8 col-lg-6">
						<h3 class="title color-light-black text-center">
							{{ $t("manual") }}
						</h3>
					</div>
				</div>
				<div
					class="d-flex justify-content-center align-items-center manuals"
					style="margin-top: 50px"
				>
					<template v-for="(i, idx) in manualList">
						<div :key="i.text" class="manual-card">
							<div class="card-img">
								<img :src="i.icon" :alt="'icon ' + i.text" />
							</div>
							{{ i.text }}
						</div>
						<div
							v-if="idx !== manualList.length - 1"
							:key="'arrow' + i.text"
							class="manual-arrow"
						>
							<div></div>
						</div>
					</template>
				</div>
			</div>
		</div>
		<!--  -->
		<benefits />
		<!--  -->
		<free-trial />
		<!--  -->
	</div>
</template>

<script>
	export default {
		name: "download",
		components: {
			downloadDevices: () => import("@/pages/download/devices"),
			benefits: () => import("@/components/benefits"),
			freeTrial: () => import("@/components/freetrial"),
		},
		data() {
			return {
				sliderPages: [
					{
						navText: this.$t("download.windows"),
						navKey: "windows",
						title: this.$t("download.windows_title"),
						desc: [
							this.$t("download.banner_list1"),
							this.$t("download.banner_list2"),
							this.$t("download.banner_list3"),
							this.$t("download.banner_list4"),
						],
						btnText: this.$t("download_item", [this.$t("download.windows")]),
						btnDownloadUrl:
							"https://share.xiongtu88.com/windows/official/download",
						btnDownloadName: "Window.exe",
						imgUrl: require("@/assets/imgs/download/windows2x.png"),
					},
					// {
					// 	navText: this.$t("download.mac"),
					// 	navKey: "mac",
					// 	title: this.$t("download.mac_title"),
					// 	desc: [
					// 		this.$t("download.banner_list1"),
					// 		this.$t("download.banner_list2"),
					// 		this.$t("download.banner_list3"),
					// 		this.$t("download.banner_list4"),
					// 	],
					// 	btnText: this.$t("download_item", [this.$t("download.mac")]),
					// 	btnDownloadUrl: "https://share.zkhtqm.com/mac/official/download",
					// 	btnDownloadName: "Mac.pkg",
					// 	imgUrl: require("@/assets/imgs/download/mac2x.png"),
					// 	imgBtn: require("@/assets/imgs/download/mac-app-store-btn.png"),
					// },
					{
						navText: this.$t("download.ios"),
						navKey: "ios",
						title: this.$t("download.ios_title"),
						desc: [
							this.$t("download.banner_list1"),
							this.$t("download.banner_list2"),
							this.$t("download.banner_list3"),
							this.$t("download.banner_list4"),
						],
						btnText: this.$t("download_item", [this.$t("download.ios")]),
						btnDownloadUrl: "https://apps.apple.com/app/id1601989276",
						btnDownloadName: "IOS.ipa",
						imgUrl: require("@/assets/imgs/download/ios2x.png"),
						// imgBtn: require("@/assets/imgs/download/app-store-btn.png"),
					},
					{
						navText: this.$t("download.android"),
						navKey: "android",
						title: this.$t("download.android_title"),
						desc: [
							this.$t("download.banner_list1"),
							this.$t("download.banner_list2"),
							this.$t("download.banner_list3"),
							this.$t("download.banner_list4"),
						],
						btnText: this.$t("download_item", [this.$t("download.android")]),
						btnDownloadUrl:
							"https://share.xiongtu88.com/android/official/download",
						btnDownloadName: "Android.apk",
						imgUrl: require("@/assets/imgs/download/android2x.png"),
					},
				],
				defaultActive: 0,
				manualList: [
					{
						icon: require("@/assets/imgs/register-icon2x.png"),
						text: this.$t("register_account"),
					},
					{
						icon: require("@/assets/imgs/download-icon2x.png"),
						text: this.$t("download_terminal"),
					},
					{
						icon: require("@/assets/imgs/buy-icon2x.png"),
						text: this.$t("purchase_line"),
					},
					{
						icon: require("@/assets/imgs/start-icon2x.png"),
						text: this.$t("start_using"),
					},
				],
			};
		},
		watch: {
			$route: function () {
				this.setSliderPage();
			},
		},
		methods: {
			setSliderPage() {
				const device = this.$route.query.device;
				if (device) {
					const deviceIndex = this.sliderPages.findIndex(
						(i) => i.navKey === device
					);
					this.defaultActive = deviceIndex;
				}
			},
		},
		created() {
			this.setSliderPage();
		},
	};
</script>

<style scoped>
	.manual {
		background-image: url(@/assets/imgs/bg-manual2x.png);
		background-position: top center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	/* Medium devices (tablets, 768px and up) */
	@media (max-width: 991.98px) {
		.manuals {
			flex-wrap: wrap;
		}
	}

	.manual-card {
		width: 230px;
		background: #ffffff;
		border: 1px solid #dcdcdc;
		text-align: center;
		display: flex;
		justify-content: center;
		padding-top: 20px;
		padding-bottom: 20px;
		padding-left: 20px;
		padding-right: 20px;
		color: #3c3c3cff;
	}
	/* Medium devices (tablets, 768px and up) */
	@media (max-width: 991.98px) {
		.manual-card {
			width: 100%;
		}
	}

	.manual-card .card-img {
		width: 25px;
		margin-right: 15px;
	}

	.manual-arrow {
		width: 30px;
	}
	.manual-arrow > div {
		border-top: 1px dashed #d1d1d1;
		position: relative;
	}
	.manual-arrow > div::after {
		content: "";
		position: absolute;
		top: -4px;
		transform: rotate(45deg);
		right: 1px;
		display: inline-block;
		width: 7px;
		height: 7px;
		border-top: 1px dashed #d1d1d1;
		border-right: 1px dashed #d1d1d1;
	}
	/* Medium devices (tablets, 768px and up) */
	@media (max-width: 991.98px) {
		.manual-arrow {
			width: 100%;
			height: 30px;
			display: flex;
			justify-content: center;
		}
		.manual-arrow > div {
			border-top: unset;
			border-left: 1px dashed #d1d1d1;
			width: 1px;
			height: 100%;
		}
		.manual-arrow > div::after {
			top: unset;
			bottom: 1px;
			transform: rotate(45deg);
			right: -3px;
			border-top: unset;
			border-bottom: 1px dashed #d1d1d1;
		}
	}
</style>