<template>
	<div>
		<div class="manual">
			<div class=" section">
                    <img class="mt-0" src="@/assets/imgs/contact/contact_001.png" style="height:200px;" />
					<div class="col-md-8 col-lg-6" style="margin: 30px auto;">
						<h3 class="title color-light-black text-center">
							{{ $t("contact") }}
						</h3>
					</div>
					<div class="col-md-8 col-lg-8 contact_1" >
						<div class="contact_11" >
                    		<img class="contact_img" src="@/assets/imgs/contact/contact_111.png"  />
							<h7 class="title color-light-black text-center contact_text1" >Telegram</h7>
							<h7 class="title color-light-black text-center contact_text2" >@wwwxm189</h7>
						</div>
						<div class="contact_11" >
                    		<img class="contact_img" src="@/assets/imgs/contact/contact_222.png"  />
							<h7 class="title color-light-black text-center contact_text1" >Wechat</h7>
							<h7 class="title color-light-black text-center contact_text2" >wwwxm189</h7>
						</div>
						<div class="contact_11" >
                    		<img class="contact_img" src="@/assets/imgs/contact/contact_333.png"  />
							<h7 class="title color-light-black text-center contact_text1" >Whatsapp</h7>
							<h7 class="title color-light-black text-center contact_text2" >+ 1(707)709-6322</h7>
						</div>
					</div>
				
				
			</div>
		</div>
		
	</div>
</template>

<script>
	export default {
		name: "contact",
		components: {
		},
		data() {
		},
		
	};
</script>

<style scoped>
	.manual {
		background-image: url(@/assets/imgs/bg-manual2x.png);
		background-position: top center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.contact_1 {
		box-shadow: 1px 4px 38px #a99f89;
		height:250px;
		margin: 0px auto;
		margin-bottom:100px;
	}

	.contact_11 {
		width:21%;
		height:170px;
		margin-top:40px;
		float:left;
		margin-left:83px;
	}

	.contact_img {
		width:50px;
		margin: 0 auto;
		display: block;
	}

	.contact_text1 {
		margin: 0 auto;
		display: block;
	}

	.contact_text2 {
		margin: 0 auto;
		display: block;
		color: #989898 !important;
	}

	/* Medium devices (tablets, 768px and up) */
	@media (max-width: 991.98px) {

		.contact_1 {
			box-shadow: 1px 4px 38px #a99f89;
			width:350px;
			height:750px;
			margin: 0px auto;
			margin-bottom:100px;
		}

		.contact_11 {
			width:170px;
			height:70px;
			margin-top:110px;

		}
	}


</style>