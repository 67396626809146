import Vue from "vue";
import Router from "vue-router";

const { routes } = require("@/router/routers");

Vue.use(Router);

export default new Router({
  // mode: "history", // 如果要移除 # 打开这个
  routes,
  scrollBehavior(to, from, savedPosition) {
    return !savedPosition ? { x: 0, y: 0 } : savedPosition; // return的结果, 就是 期望滚动到哪个的位置
  }
});
