<template>
	<div :class="`carousel-item ${active ? 'active' : ''}`">
		<!-- <img
			:id="'carousel-bg' + index"
			:src="bgImgUrl"
			:alt="bgImgAlt"
			class="carousel-bg d-block w-100"
		/> -->
		<div :id="'carousel-bg' + index" class="d-block w-100 carousel-bg" />
		<div class="carousel-caption d-block">
			<div class="container">
				<div class="row align-items-center">
					<div class="col-md-5 px-0 text-center text-md-left">
						<h3 class="title">
							{{ title }}
						</h3>
						<p class="carousel-desc color-gray mt-3">{{ description }}</p>
						<router-link
							v-if="toUrl"
							:to="toUrl"
							class="btn btn-main caption-btn"
						>
							{{ toText }}
						</router-link>
						<a v-if="href" :href="href" class="btn btn-main caption-btn">
							{{ aText }}
						</a>
					</div>
					<div v-if="imgUrl" class="col-md-7 px-0 text-center">
						<img :src="imgUrl" :alt="imgAlt" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "sliderItem",
		props: {
			active: {
				type: Boolean,
				default: false,
			},
			data: Object,
			index: Number,
		},
		computed: {
			bgImgUrl: function () {
				return this.data.bgImgUrl;
			},
			bgImgAlt: function () {
				return this.data.bgImgAlt;
			},
			title: function () {
				return this.data.title;
			},
			description: function () {
				return this.data.desc;
			},
			imgUrl: function () {
				return this.data.imgUrl;
			},
			imgAlt: function () {
				return this.data.imgAlt;
			},
			toText: function () {
				return this.data.toText;
			},
			toUrl: function () {
				return this.data.toUrl;
			},
			aText: function () {
				return this.data.aText;
			},
			href: function () {
				return this.data.href;
			},
		},
		methods: {
			rezise() {
				if (window.innerWidth < 768) {
					document.getElementById(
						"carousel-bg" + this.index
					).style.backgroundImage = `url(${this.data.bgImgMobileUrl})`;
				} else {
					document.getElementById(
						"carousel-bg" + this.index
					).style.backgroundImage = `url(${this.data.bgImgUrl})`;
				}
			},
		},
		mounted() {
			this.rezise();
			window.addEventListener("resize", this.rezise);
		},
		beforeDestroy() {
			window.removeEventListener("resize", this.rezise);
		},
	};
</script>

<style scoped>
	.carousel-bg {
		height: 400px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}
	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.carousel-bg {
			height: 550px;
		}
	}
	/* Small devices (landscape phones, 576px and up) */
	@media (min-width: 576px) and (max-width: 767.98px) {
		.carousel-bg {
			height: 550px;
		}
	}
	/* Medium devices (tablets, 768px and up) */
	@media (min-width: 768px) and (max-width: 991.98px) {
		.carousel-bg {
			height: 500px;
		}
	}
	/* Large devices (desktops, 992px and up) */
	@media (min-width: 992px) and (max-width: 1199.98px) {
		.carousel-bg {
			height: 550px;
		}
	}
	/* Extra large devices (large desktops, 1200px and up) */
	@media (min-width: 1200px) {
		.carousel-bg {
			height: 600px;
		}
	}

	.carousel-caption {
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);
		bottom: unset;
		right: 15%;
		left: 15%;
		z-index: 10;
		padding-top: 20px;
		padding-bottom: 20px;
		color: #fff;
		text-align: left;
	}
	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.carousel-caption {
			top: 20px;
			transform: unset;
		}
	}
	/* Small devices (landscape phones, 576px and up) */
	@media (min-width: 576px) and (max-width: 767.98px) {
		.carousel-caption {
			top: 30px;
			transform: unset;
		}
	}
	/* Medium devices (tablets, 768px and up) */
	@media (min-width: 768px) and (max-width: 991.98px) {
		.carousel-caption {
			right: 15px;
			left: 15px;
		}
	}

	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.carousel-caption .title {
			line-height: 2rem;
		}
	}
	/* Small devices (landscape phones, 576px and up) */
	@media (min-width: 576px) and (max-width: 767.98px) {
		.carousel-caption .title {
			line-height: 2.2rem;
		}
	}

	.carousel-desc {
		font-size: 16px;
	}
	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.carousel-desc {
			font-size: 15px;
		}
	}
	/* Small devices (landscape phones, 576px and up) */
	@media (min-width: 576px) and (max-width: 767.98px) {
		.carousel-desc {
			font-size: 15px;
		}
	}

	.caption-btn {
		margin-top: 60px;
	}
	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.caption-btn {
			margin-top: 180px;
		}
	}
	/* Small devices (landscape phones, 576px and up) */
	@media (min-width: 576px) and (max-width: 767.98px) {
		.caption-btn {
			margin-top: 250px;
		}
	}
	/* Medium devices (tablets, 768px and up) */
	@media (min-width: 768px) and (max-width: 991.98px) {
		.caption-btn {
			margin-top: 10px;
		}
	}
</style>