const home = "home";
const product = "product";
const exclusive = "exclusive";
const shared = "shared";
const download = "download";
const contact = "contact";
const serverLocation = "server";
const blog = "blog";

const keys = {
  // api
  baseApi: process.env.BASE_API,
  // cookie
  v: "version",
  lang: "language",
  // localstorage
  channel: "channel",
  // path
  home,
  product,
  exclusive,
  shared,
  download,
  contact,
  serverLocation,
  blog,
  blogPost: "blogPost",
  // side nav menu
  headerMenu: [
    { el: "link", title: "home", to: `/` },
    {
      el: "link",
      title: "product",
      to: `/${product}/${exclusive}`
      // child: [
      //   {
      //     title: "exclusive",
      //     to: `/${exclusive}`
      //   }, {
      //     title: "shared",
      //     to: `/${shared}`
      //   }
      // ]
    },
    { el: "link", title: "download", to: `/${download}` },
    { el: "link", title: "server_location", to: `/${serverLocation}` },
    { el: "link", title: "blog", to: `/${blog}` }
  ],
  footerMenu: [
    {
      child: [
        {
          text: "product",
          to: `/${product}/${exclusive}`
        },
        // {
        //   text: "exclusive_route_mode",
        //   to: `/`,
        // },
        // {
        //   text: "shared_route_mode",
        //   to: `/`,
        // },
        {
          text: "server_list",
          to: `/${serverLocation}`
        },
        {
          text: "blog",
          to: `/${blog}`
        }
      ]
    },
    {
      child: [
        {
          text: "download",
          to: `/${download}`
        },
        {
          text: "windows",
          to: { path: `/${download}`, query: { device: "windows" } }
        },
        {
          text: "android",
          to: { path: `/${download}`, query: { device: "android" } }
        },
        {
          text: "mac",
          to: { path: `/${download}`, query: { device: "mac" } }
        },
        {
          text: "ios",
          to: { path: `/${download}`, query: { device: "ios" } }
        }
      ]
    },
    {
      child: [
        {
          text: "contact_us",
          to: `/${contact}`
        },
        {
          text: "recruiting_agent",
          to: `/`,
          disabled: "disabled"
        },
        {
          text: "users_guidance",
          to: `/`,
          disabled: "disabled"
        },
        {
          text: "privacy_policy",
          to: `/`,
          disabled: "disabled"
        }
      ]
    }
  ]
};

export default keys;
