<template>
	<div>
		<div class="container-fluid p-0 bg-dark-blue">
			<div class="container section banner">
				<div class="row align-items-center justify-content-center">
					<div class="col-md-4 color-white text-center text-md-left">
						<h3 class="title">
							{{ $t("product.exclusive.exclusive_line") }}
						</h3>
						<p class="color-gray mt-3">
							{{ $t("product.exclusive.banner") }}
						</p>
						<a :href="toDashboardLogin" class="btn btn-main banner-btn">
							{{ $t("start_using") }}
						</a>
					</div>
					<div class="col-md-6 d-none d-md-block">
						<img
							src="@/assets/imgs/exclusive-banner2x.png"
							:alt="$t('product.exclusive.exclusive_line')"
						/>
					</div>
				</div>
			</div>
		</div>
		<!--  -->
		<div class="advantage">
			<div class="container section">
				<div class="row justify-content-center">
					<div class="col-md-8 col-lg-6">
						<h3 class="title text-center">
							{{ $t("product.exclusive.advantage") }}
						</h3>
					</div>
				</div>
				<exclusive-advantage />
			</div>
		</div>
		<!--  -->
		<div class="container section bg-white">
			<div class="row justify-content-center">
				<div class="col-md-8 col-lg-6 text-center">
					<h3 class="title" style="margin-bottom: 20px">
						{{ $t("product.exclusive.quotation") }}
					</h3>
					<p class="subtitle">
						{{ $t("product.exclusive.quotation_desc") }}
					</p>
				</div>
			</div>
			<img src="@/assets/imgs/exclusive/quotation2x.png" alt="quotation" />
			<div class="row justify-content-center mt-3">
				<div class="col-10">
					<div class="bg-orange-light text-center py-3">
						<p class="color-dark-orange mb-0">
							{{ $t("product.exclusive.quotation_badge") }}
						</p>
					</div>
				</div>
			</div>
			<div class="d-flex justify-content-center" style="margin-top: 50px">
				<a :href="toDashboardRegister" class="btn btn-outline-main mx-2"
					>{{ $t("register_an_account") }}
				</a>
				<a href="" class="btn btn-main mx-2">
					{{ $t("customer_service") }}
				</a>
			</div>
		</div>
		<!--  -->
		<free-trial />
		<!--  -->
	</div>
</template>

<script>
	import { keys, localS } from "@/utils";

	export default {
		name: "product-exclusive",
		components: {
			exclusiveAdvantage: () => import("@/pages/product/exclusive/e-advantage"),
			freeTrial: () => import("@/components/freetrial"),
		},
		data() {
			let urlQuerys = "";
			if (localS.get(keys.channel)) {
				urlQuerys = "?channel=" + localS.get(keys.channel);
			}
			return {
				toDashboardLogin: process.env.DASHBOARD_URL + urlQuerys,
				toDashboardRegister:
					process.env.DASHBOARD_URL + "/register" + urlQuerys,
			};
		},
	};
</script>

<style scoped>
	.banner {
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}
	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.banner {
			background-image: url(@/assets/imgs/exclusive-banner-mobile2x.png);
		}
	}
	/* Small devices (landscape phones, 576px and up) */
	@media (min-width: 576px) and (max-width: 767.98px) {
		.banner {
			background-image: url(@/assets/imgs/exclusive-banner-mobile2x.png);
		}
	}

	.banner-btn {
		margin-top: 60px;
	}
	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.banner-btn {
			margin-top: 350px;
		}
	}
	/* Small devices (landscape phones, 576px and up) */
	@media (min-width: 576px) and (max-width: 767.98px) {
		.banner-btn {
			margin-top: 350px;
		}
	}

	.advantage {
		background-image: url(@/assets/imgs/bg-privacy2x.png);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}
</style>