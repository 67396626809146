<template>
	<div>
		<app-header :key="'header' + $i18n.locale" :isLight="isLight" />
		<router-view
			class="user-select-none"
			:style="`min-height: 70vh; margin-top: 65px; background-color: #${
				isLight ? 'F4F5F6' : 'ffffff'
			}`"
			:key="$i18n.locale"
		/>
		<!-- key: $i18n.locale (is for force update all UI) -->
		<app-footer :key="'footer' + $i18n.locale" />
	</div>
</template>

<script>
	import { keys, cookie, localS } from "@/utils";

	export default {
		name: "App",
		components: {
			appHeader: () => import("@/components/header"),
			appFooter: () => import("@/components/footer"),
		},
		data() {
			return {
				isLight: false,
			};
		},
		watch: {
			$route: function () {
				this.checkVersion();
				this.checkPath();
			},
		},
		methods: {
			checkVersion() {
				const currentVersion = cookie.getCookie(keys.v);
				if (!currentVersion || currentVersion !== process.env.APP_VERSION) {
					let cookies = document.cookie.split(";");

					cookies.forEach((cookie) => {
						let eqPos = cookie.indexOf("=");
						let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
						document.cookie =
							name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
					});

					cookie.setCookie(keys.v, process.env.APP_VERSION);
					location.reload();
				}
			},
			checkPath() {
				const currLocation = window.location.pathname;
				if (currLocation === `/${keys.blog}`) {
					this.isLight = true;
				} else {
					this.isLight = false;
				}
			},
			getQuery() {
				const urlParams = new URLSearchParams(window.location.search);
				const channel = urlParams.get("channel");
				if (channel) {
					localS.set(keys.channel, channel);
				}
			},
		},
		mounted() {
			this.checkPath();
			this.getQuery();
		},
	};
</script>

<style>
	@import "~bootstrap/dist/css/bootstrap.css";
	@import "~bootstrap-icons/font/bootstrap-icons.css";
	@import "@/assets/styles/main.css";
</style>