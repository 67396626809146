<template>
	<div>
		<div class="container blogs">
			<router-link :to="firstBlogs.link" class="text-decoration-none">
				<div class="bg-white shadow p-3">
					<div class="row">
						<div
							class="col-md-5 overflow-hidden"
							style="max-height: 250px"
						>
							<img :src="firstBlogs.imgUrl" />
						</div>
						<div class="col-md-7">
							<div class="d-flex flex-wrap h-100">
								<div class="align-self-start">
									<p class="b-blog-title">
										{{ $t("blog." + firstBlogs.title) }}
									</p>
								</div>
								<div
									class="
										d-flex
										align-self-end
										justify-content-between
										w-100
									"
								>
									<p class="b-blog-date">
										{{ $d(firstBlogs.create, "short") }}
									</p>
									<p class="b-blog-link">{{ $t("see_more") }} &gt;</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</router-link>
			<!--  -->
			<div class="row mt-3">
				<div
					v-for="i in blogs"
					:key="i.title"
					class="col-sm-6 col-md-4"
					style="margin-top: 40px"
				>
					<router-link
						:to="{ name: nameBlogPost, params: { id: i.id } }"
						class="text-decoration-none"
					>
						<div class="bg-white shadow p-3">
							<div class="blog-img">
								<img
									:src="require('@/assets/imgs/blogs/' + i.imgUrl)"
									:alt="'blog ' + $t('blog.' + i.title)"
								/>
							</div>
							<div class="px-3 pt-4">
								<div class="d-flex flex-wrap" style="height: 120px">
									<div class="align-self-start w-100">
										<p class="blog-title">
											{{ $t("blog." + i.title) }}
										</p>
									</div>
									<div class="align-self-end">
										<p class="blog-date">
											{{ $d(i.create, "short") }}
										</p>
									</div>
								</div>
							</div>
						</div>
					</router-link>
				</div>
			</div>
			<!--  -->
			<pagination
				v-show="blogs.length > 9"
				class="mt-5"
				noBorder
				:limit="limit"
				:current="page"
				:total="blogs.length"
				:onPageChange="onPageChange"
			/>
			<!--  -->
		</div>
	</div>
</template>

<script>
	import blogsJson from "./blogs.json";
	import { keys } from "@/utils";
	export default {
		name: "blog",
		components: {
			pagination: () => import("@/components/pagination"),
		},
		data() {
			return {
				list: blogsJson,
				limit: 9,
				page: 1,
				nameBlogPost: keys.blogPost, // for route link blog post name
			};
		},
		computed: {
			firstBlogs() {
				return {
					link: { name: keys.blogPost, params: { id: this.list[0].id } },
					...this.list[0],
					imgUrl: require("@/assets/imgs/blogs/" + this.list[0].imgUrl),
				};
			},
			blogs() {
				const blogs = [...this.list];
				blogs.shift();
				return blogs;
			},
		},
		methods: {
			onPageChange(page) {
				this.page = page;
			},
		},
	};
</script>

<style scoped>
	.blogs {
		padding-top: 20px;
		padding-bottom: 30px;
	}
	/* big blog */
	.b-blog-title {
		margin-top: 15px;
		margin-bottom: 0px;
		font-size: 33px;
		font-weight: bold;
		color: #282828;
		line-height: 46px;
	}
	.b-blog-date {
		font-size: 16px;
		color: #939393;
		line-height: 22px;
	}
	.b-blog-link {
		margin-right: 30px;
		font-size: 16px;
		color: #3373f3;
	}
	/* small blog */
	.blog-img {
		height: 200px;
		overflow: hidden;
	}
	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.blog-img {
			height: unset;
			max-height: 200px;
			overflow: hidden;
		}
	}
	/* Medium devices (tablets, 768px and up) */
	@media (min-width: 768px) and (max-width: 991.98px) {
		.blog-img {
			height: 100px;
			overflow: hidden;
		}
	}
	/* Large devices (desktops, 992px and up) */
	@media (min-width: 992px) and (max-width: 1199.98px) {
		.blog-img {
			height: 160px;
			overflow: hidden;
		}
	}
	.blog-title {
		margin-bottom: 0px;
		font-size: 20px;
		font-weight: bold;
		color: #282828;
		line-height: 30px;
	}
	.blog-date {
		margin-bottom: 0px;
		font-size: 16px;
		color: #939393;
		line-height: 22px;
	}
	/*  */
	.btn-more {
		font-size: 16px;
		color: #3474f3;
		line-height: 22px;
		background-color: #ffffff;
		text-align: center;
		padding-top: 15px;
		padding-bottom: 15px;
	}
</style>