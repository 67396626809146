<template>
	<div>
		<div class="container blogpost">
			<div class="bg-white shadow p-3 p-md-5">
				<div class="blogpost-img">
					<img
						v-if="post"
						:src="require('@/assets/imgs/blogs/' + post.imgUrl)"
						:alt="'blog ' + $t('blog.' + post.title)"
					/>
				</div>
				<h1 class="blogpost-title">{{ $t("blog." + post.title) }}</h1>
				<p class="blogpost-date">{{ post.date }}</p>
				<p class="blogpost-details" v-html="$t('blog.' + post.details)">
					{{ $t("blog." + post.details) }}
				</p>
			</div>
		</div>
	</div>
</template>

<script>
	import blogsJson from "./blogs.json";
	export default {
		name: "blog-post",
		data() {
			return {
				post: null,
			};
		},
		created() {
			// find by blog id
			const post = blogsJson.find((i) => i.id === this.$route.params.id);
			if (post) {
				this.post = post;
			} else {
				this.$router.go(-1);
			}
		},
	};
</script>

<style scoped>
	.blogpost {
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.blogpost-img {
		text-align: center;
	}
	.blogpost-img img {
		width: 60%;
	}
	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.blogpost-img img {
			width: 100%;
		}
	}

	.blogpost-title {
		margin-top: 35px;
		font-size: 33px;
		font-weight: bold;
		color: #282828;
		line-height: 46px;
	}
	.blogpost-date {
		font-size: 16px;
		color: #939393;
		line-height: 22px;
	}
	.blogpost-details {
		font-size: 18px;
		color: #282828;
		line-height: 30px;
	}
</style>