<template>
	<nav>
		<ul :class="`pagination pagination-sm justify-content-${align} m-0`">
			<li :class="'page-item' + (current < 2 ? ' disabled' : '')">
				<a
					:class="'page-link' + (noBorder ? ' border-0' : '')"
					href="javascript:void(0);"
					aria-label="Previous"
					@click="prev"
				>
					<span aria-hidden="true">&#10094;</span>
				</a>
			</li>
			<li v-if="current > 14" class="page-item">
				<a
					:class="'page-link' + (noBorder ? ' border-0' : '')"
					href="javascript:void(0);"
					@click="clicked(current - 10 >= 1 ? current - 10 : 1)"
					>{{ current - 10 >= 1 ? current - 10 : 1 }}</a
				>
			</li>
			<li v-if="current > 14" class="page-item">
				<span>&hellip;</span>
			</li>
			<li
				v-for="index in range(
					current < 3
						? 1
						: current + 2 <= pageMax
						? current - 2
						: pageMax - 4 > 0
						? pageMax - 4
						: 1, // min
					pageMax < 5
						? pageMax
						: current < pageMax
						? current < 3
							? 5
							: current + 2 < pageMax
							? current + 2
							: pageMax
						: pageMax // max
				)"
				:key="index"
				:class="'page-item' + (index === current ? ' active' : '')"
			>
				<a
					:class="'page-link' + (noBorder ? ' border-0' : '')"
					href="javascript:void(0);"
					@click="clicked(index)"
					>{{ index }}</a
				>
			</li>
			<li v-if="pageMax > 15 && current < pageMax - 5" class="page-item">
				<span>&hellip;</span>
			</li>
			<li v-if="pageMax > 15 && current < pageMax - 5" class="page-item">
				<a
					:class="'page-link' + (noBorder ? ' border-0' : '')"
					href="javascript:void(0);"
					@click="
						clicked(current + 10 >= pageMax ? pageMax : current + 10)
					"
					>{{ current + 10 >= pageMax ? pageMax : current + 10 }}</a
				>
			</li>
			<li :class="'page-item' + (current === pageMax ? ' disabled' : '')">
				<a
					:class="'page-link' + (noBorder ? ' border-0' : '')"
					href="javascript:void(0);"
					aria-label="Next"
					@click="next"
				>
					<span aria-hidden="true">&#10095;</span>
				</a>
			</li>
			<li>
				<span
					class="d-block text-muted"
					style="padding: 0.25rem 0.5rem; font-size: 0.87rem"
				>
					{{ $t("total_page_count", [total]) }}
				</span>
			</li>
		</ul>
	</nav>
</template>

<script>
	export default {
		name: "pagination",
		props: {
			align: {
				type: String,
				default: "center",
			},
			noBorder: {
				type: Boolean,
				default: false,
			},
			limit: {
				type: Number,
				default: 5,
			},
			total: {
				type: Number,
				default: 0,
			},
			current: {
				type: Number,
				default: 1,
			},
			onPageChange: {
				type: Function,
				default: () => {},
			},
		},
		data() {
			return {
				pageMax: Math.ceil(this.total / this.limit),
			};
		},
		methods: {
			prev() {
				if (this.current > 1) {
					this.onPageChange(this.current - 1);
				}
			},
			next() {
				if (this.current < this.pageMax) {
					this.onPageChange(this.current + 1);
				}
			},
			clicked(page) {
				this.onPageChange(page);
			},
			range(min, max) {
				let array = [],
					j = 0;
				for (let i = min; i <= max; i++) {
					array[j] = i;
					j++;
				}
				return array;
			},
		},
	};
</script>

<style scoped>
	.page-link {
		color: #2368f0;
	}

	.page-link:focus {
		box-shadow: unset;
	}

	.page-item.disabled .page-link {
		color: #6c757d;
	}
</style>