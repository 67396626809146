import Cookies from "js-cookie";

export function setCookie(key, value, expires) {
  if (expires) {
    // set expires days from now
    Cookies.set(key, value, { expires });
  } else {
    Cookies.set(key, value);
  }
}

export function delCookie(key) {
  Cookies.remove(key);
}

export function getCookie(key) {
  return Cookies.get(key);
}

export function getStoreValue(key, item) {
  const store = Cookies.get(key) ? JSON.parse(Cookies.get(key)) : {};
  return item in store ? store[item] : "";
}

export function getJson(key, defaultJson = {}) {
  return Cookies.get(key) ? JSON.parse(Cookies.get(key)) : defaultJson;
}
