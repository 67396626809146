var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',[_c('ul',{class:("pagination pagination-sm justify-content-" + _vm.align + " m-0")},[_c('li',{class:'page-item' + (_vm.current < 2 ? ' disabled' : '')},[_c('a',{class:'page-link' + (_vm.noBorder ? ' border-0' : ''),attrs:{"href":"javascript:void(0);","aria-label":"Previous"},on:{"click":_vm.prev}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("❮")])])]),_vm._v(" "),(_vm.current > 14)?_c('li',{staticClass:"page-item"},[_c('a',{class:'page-link' + (_vm.noBorder ? ' border-0' : ''),attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.clicked(_vm.current - 10 >= 1 ? _vm.current - 10 : 1)}}},[_vm._v(_vm._s(_vm.current - 10 >= 1 ? _vm.current - 10 : 1))])]):_vm._e(),_vm._v(" "),(_vm.current > 14)?_c('li',{staticClass:"page-item"},[_c('span',[_vm._v("…")])]):_vm._e(),_vm._v(" "),_vm._l((_vm.range(
				_vm.current < 3
					? 1
					: _vm.current + 2 <= _vm.pageMax
					? _vm.current - 2
					: _vm.pageMax - 4 > 0
					? _vm.pageMax - 4
					: 1, // min
				_vm.pageMax < 5
					? _vm.pageMax
					: _vm.current < _vm.pageMax
					? _vm.current < 3
						? 5
						: _vm.current + 2 < _vm.pageMax
						? _vm.current + 2
						: _vm.pageMax
					: _vm.pageMax // max
			)),function(index){return _c('li',{key:index,class:'page-item' + (index === _vm.current ? ' active' : '')},[_c('a',{class:'page-link' + (_vm.noBorder ? ' border-0' : ''),attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.clicked(index)}}},[_vm._v(_vm._s(index))])])}),_vm._v(" "),(_vm.pageMax > 15 && _vm.current < _vm.pageMax - 5)?_c('li',{staticClass:"page-item"},[_c('span',[_vm._v("…")])]):_vm._e(),_vm._v(" "),(_vm.pageMax > 15 && _vm.current < _vm.pageMax - 5)?_c('li',{staticClass:"page-item"},[_c('a',{class:'page-link' + (_vm.noBorder ? ' border-0' : ''),attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.clicked(_vm.current + 10 >= _vm.pageMax ? _vm.pageMax : _vm.current + 10)}}},[_vm._v(_vm._s(_vm.current + 10 >= _vm.pageMax ? _vm.pageMax : _vm.current + 10))])]):_vm._e(),_vm._v(" "),_c('li',{class:'page-item' + (_vm.current === _vm.pageMax ? ' disabled' : '')},[_c('a',{class:'page-link' + (_vm.noBorder ? ' border-0' : ''),attrs:{"href":"javascript:void(0);","aria-label":"Next"},on:{"click":_vm.next}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("❯")])])]),_vm._v(" "),_c('li',[_c('span',{staticClass:"d-block text-muted",staticStyle:{"padding":"0.25rem 0.5rem","font-size":"0.87rem"}},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t("total_page_count", [_vm.total]))+"\n\t\t\t")])])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }