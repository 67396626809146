
<template>
	<div class="bg-light-gray">
		<div class="container section">
			<div class="row justify-content-center">
				<div class="col-md-8 col-lg-6">
					<h3 class="title color-light-black text-center">
						{{ $t("vpn_benefit") }}
					</h3>
				</div>
			</div>
			<div class="row" style="margin-top: 50px">
				<div class="col-md-6">
					<img src="@/assets/imgs/vpn-benefits2x.png" alt="vpn benefits" />
				</div>
				<div class="col-md-6">
					<template v-for="i in benefits">
						<div :key="i.title" class="d-flex align-items-end">
							<div class="benefit-count">
								<img :src="i.imgUrl" alt="number" />
							</div>
							<h5 class="title color-light-black mb-0">
								{{ i.title }}
							</h5>
						</div>
						<div :key="i.desc" class="benefit-content">
							<p class="mb-0" v-html="i.desc">
								{{ i.desc }}
							</p>
						</div>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "benefits",
		data() {
			return {
				benefits: [
					{
						imgUrl: require("@/assets/imgs/number/1@2x.png"),
						title: this.$t("benefit1_title"),
						desc: this.$t("benefit1_desc"),
					},
					{
						imgUrl: require("@/assets/imgs/number/2@2x.png"),
						title: this.$t("benefit2_title"),
						desc: this.$t("benefit2_desc"),
					},
					{
						imgUrl: require("@/assets/imgs/number/3@2x.png"),
						title: this.$t("benefit3_title"),
						desc: this.$t("benefit3_desc"),
					},
				],
			};
		},
	};
</script>

<style scoped>
	.benefit-count {
		width: 23px;
		margin-right: 15px;
	}
	.benefit-content {
		color: #3c3c3c;
		margin-left: 13px;
		margin-top: 8px;
		padding: 15px 20px 15px 25px;
	}
	.benefit-content:not(:last-child) {
		margin-left: 10px;
		border-left: #e3e3e3 solid 2px;
	}
</style>