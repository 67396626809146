<template>
	<div id="slider_download" class="carousel" data-ride="carousel">
		<div class="device-indicators">
			<ol class="device-indicators-box">
				<li
					v-for="(i, idx) in pages"
					:key="idx"
					data-target="#slider_download"
					:data-slide-to="idx"
					:class="idx === active ? 'active' : ''"
					@click="active = idx"
				>
					{{ i.navText }}
				</li>
			</ol>
		</div>
		<div class="carousel-inner">
			<!--  -->
			<div
				v-for="(i, idx) in pages"
				:key="idx"
				:class="`carousel-item ${idx === active ? 'active' : ''}`"
			>
				<div class="d-block w-100 carousel-background" />
				<div class="carousel-caption d-block">
					<div class="container">
						<div class="row">
							<div class="col-md-6 px-0 text-center text-md-left">
								<h3 class="title mt-4 mb-4">
									{{ i.title }}
								</h3>
								<template v-if="Array.isArray(i.desc)">
									<ul class="list-style2">
										<li v-for="j in i.desc" :key="j" v-html="j">
											{{ j }}
										</li>
									</ul>
								</template>
								<p v-else class="color-gray mt-3">{{ i.desc }}</p>
								<div
									class="
										d-flex
										justify-content-center justify-content-md-start
									"
								>
									<a
										v-if="i.btnText"
										:href="i.btnDownloadUrl"
										:download="'GT_NET_' + i.btnDownloadName"
										class="btn btn-main mx-2"
									>
										{{ i.btnText }}
									</a>
									<a
										v-if="i.imgBtn"
										href="#"
										class="mx-2"
										style="width: 151px"
									>
										<img :src="i.imgBtn" :alt="i.toText" />
									</a>
									<router-link
										v-if="i.toUrl"
										:to="i.toUrl"
										class="btn btn-main mx-2"
									>
										{{ i.toText }}
									</router-link>
								</div>
							</div>
							<div class="col-md-6 px-0 text-center">
								<img
									:src="i.imgUrl"
									:alt="i.navText"
									class="carousel-caption-img"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!--  -->
		</div>
	</div>
</template>

<script>
	export default {
		name: "download-devices",
		props: {
			pages: {
				type: Array,
				default: () => [],
			},
			defaultActive: Number,
		},
		watch: {
			defaultActive: function (newVal) {
				this.active = newVal;
			},
		},
		data() {
			return {
				active: this.defaultActive || 0,
			};
		},
		mounted() {
			$("#slider_download").carousel({
				interval: false,
			});
		},
	};
</script>

<style scoped>
	.device-indicators {
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 15;
		margin-right: 15%;
		margin-left: 15%;
		overflow-x: auto;
		overflow-y: visible;
		/* hide scrollbar */
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
	.device-indicators::-webkit-scrollbar {
		display: none;
	}
	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.device-indicators {
			overflow: hidden;
			bottom: 15px;
			margin-right: 15px;
			margin-left: 15px;
		}
	}
	/* Small devices (landscape phones, 576px and up) */
	@media (min-width: 576px) and (max-width: 767.98px) {
		.device-indicators {
			overflow: hidden;
			bottom: 15px;
			margin-right: 15px;
			margin-left: 15px;
		}
	}

	.device-indicators-box {
		width: 900px;
		list-style: none;
		display: -ms-flexbox;
		display: flex;
		border-top: #414245ff solid 1px;
		padding-left: 50px;
		padding-right: 50px;
		margin-top: 6px;
		margin-bottom: 0px;
		margin-left: auto;
		margin-right: auto;
		justify-content: center;
	}
	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.device-indicators-box {
			width: 100%;
			padding-left: 0;
			padding-right: 0;
		}
	}
	/* Small devices (landscape phones, 576px and up) */
	@media (min-width: 576px) and (max-width: 767.98px) {
		.device-indicators-box {
			width: 100%;
			padding-left: 0;
			padding-right: 0;
		}
	}

	.device-indicators-box li {
		min-width: 200px;
		color: #646465ff;
		padding-top: 15px;
		padding-bottom: 15px;
		text-align: center;
		border-top-left-radius: 1.5px;
		border-top-right-radius: 1.5px;
		border-top: #414245ff solid 2px;
		margin-top: -2px;
	}
	.device-indicators-box li.active {
		color: #3373f3ff;
		border-top: #3373f3ff solid 6px;
		margin-top: -6px;
	}
	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.device-indicators-box li {
			min-width: unset;
			flex: 1;
		}
	}
	/* Small devices (landscape phones, 576px and up) */
	@media (min-width: 576px) and (max-width: 767.98px) {
		.device-indicators-box li {
			min-width: unset;
			flex: 1;
		}
	}

	.carousel-background {
		min-height: 650px;
		background: rgb(23, 27, 33);
		background: linear-gradient(
			180deg,
			rgba(23, 27, 33, 1) 70%,
			rgba(34, 38, 44, 1) 70%
		);
	}
	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.carousel-background {
			background: linear-gradient(
				180deg,
				rgba(23, 27, 33, 1) 65%,
				rgba(34, 38, 44, 1) 65%
			);
		}
	}
	/* Small devices (landscape phones, 576px and up) */
	@media (min-width: 576px) and (max-width: 767.98px) {
		.carousel-background {
			background: linear-gradient(
				180deg,
				rgba(23, 27, 33, 1) 75%,
				rgba(34, 38, 44, 1) 75%
			);
		}
	}
	/* Medium devices (tablets, 768px and up) */
	@media (min-width: 768px) and (max-width: 991.98px) {
		.carousel-background {
			min-height: 500px;
		}
	}

	.carousel-caption {
		position: absolute;
		top: 15%;
		/* transform: translate(0, -50%); */
		bottom: unset;
		right: 15%;
		left: 15%;
		z-index: 10;
		padding-top: 20px;
		padding-bottom: 20px;
		color: #fff;
		text-align: left;
	}
	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.carousel-caption {
			top: 0;
			right: 15px;
			left: 15px;
		}
	}
	/* Small devices (landscape phones, 576px and up) */
	@media (min-width: 576px) and (max-width: 767.98px) {
		.carousel-caption {
			top: 0;
			right: 15px;
			left: 15px;
		}
	}
	/* Medium devices (tablets, 768px and up) */
	@media (min-width: 768px) and (max-width: 991.98px) {
		.carousel-caption {
			top: 0;
			right: 15px;
			left: 15px;
		}
	}

	.carousel-caption-img {
		width: auto;
		height: 400px;
	}
	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.carousel-caption-img {
			margin-top: 25px;
			height: 160px;
		}
	}
	/* Small devices (landscape phones, 576px and up) */
	@media (min-width: 576px) and (max-width: 767.98px) {
		.carousel-caption-img {
			margin-top: 25px;
			height: 250px;
		}
	}
</style>