// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../assets/imgs/bg-calander2x.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.free-trial[data-v-9917e3f8] {\n\tbackground-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n\tbackground-repeat: no-repeat;\n\tbackground-position: center;\n\tbackground-size: cover;\n}\n", "",{"version":3,"sources":["webpack://./src/components/freetrial.vue"],"names":[],"mappings":";AAoCA;CACA,yDAAA;CACA,4BAAA;CACA,2BAAA;CACA,sBAAA;AACA","sourcesContent":["\n<template>\n\t<div class=\"free-trial\">\n\t\t<div class=\"container section\">\n\t\t\t<div class=\"row justify-content-center\">\n\t\t\t\t<div class=\"col-md-8 col-lg-6\">\n\t\t\t\t\t<h3 class=\"title text-center color-white\">\n\t\t\t\t\t\t{{ $t(\"s_day_free_trial\") }}\n\t\t\t\t\t</h3>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class=\"d-flex justify-content-center\" style=\"margin-top: 20px\">\n\t\t\t\t<a :href=\"toDashboardLogin\" class=\"btn btn-main\">\n\t\t\t\t\t{{ $t(\"get_it_now\") }}\n\t\t\t\t</a>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</template>\n\n<script>\n\timport { keys, localS } from \"@/utils\";\n\n\texport default {\n\t\tname: \"free-trial\",\n\t\tdata() {\n\t\t\tlet urlQuerys = \"\";\n\t\t\tif (localS.get(keys.channel)) {\n\t\t\t\turlQuerys = \"?channel=\" + localS.get(keys.channel);\n\t\t\t}\n\t\t\treturn { toDashboardLogin: process.env.DASHBOARD_URL + urlQuerys };\n\t\t},\n\t};\n</script>\n\n<style scoped>\n\t.free-trial {\n\t\tbackground-image: url(@/assets/imgs/bg-calander2x.png);\n\t\tbackground-repeat: no-repeat;\n\t\tbackground-position: center;\n\t\tbackground-size: cover;\n\t}\n</style>"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
