import { keys } from "@/utils";

export const routes = [
  {
    path: "/",
    name: keys.home,
    component: () =>
      import(/* webpackChunkName: "home" */ `@/pages/${keys.home}`),
  },
  {
    path: `/${keys.product}`,
    name: keys.product,
    redirect: `${keys.product}/${keys.exclusive}"`,
    component: () =>
      import(/* webpackChunkName: "product" */ `@/pages/${keys.product}`),
    children: [
      {
        path: keys.exclusive,
        name: keys.product + keys.exclusive,
        component: () =>
          import(/* webpackChunkName: "product" */ `@/pages/${keys.product}/${keys.exclusive}`),
      },
      {
        path: keys.shared,
        name: keys.product + keys.shared,
        component: () =>
          import(/* webpackChunkName: "product" */ `@/pages/${keys.product}/${keys.shared}`),
      },
    ]
  },
  {
    path: `/${keys.download}`,
    name: keys.download,
    component: () =>
      import(/* webpackChunkName: "download" */ `@/pages/${keys.download}`),
  },
  {
    path: `/${keys.contact}`,
    name: keys.contact,
    component: () =>
      import(/* webpackChunkName: "contact" */ `@/pages/${keys.contact}`),
  },
  {
    path: `/${keys.serverLocation}`,
    name: keys.serverLocation,
    component: () =>
      import(/* webpackChunkName: "server" */ `@/pages/${keys.serverLocation}`),
  },
  {
    path: `/${keys.blog}`,
    name: keys.blog,
    component: () =>
      import(/* webpackChunkName: "blog" */ `@/pages/${keys.blog}`),
  },
  {
    path: `/${keys.blog}/:id`,
    name: keys.blogPost,
    component: () =>
      import(/* webpackChunkName: "blogPost" */ `@/pages/${keys.blog}/post`),
  },
  {
    path: "*", // 404
    redirect: "/"
  }
];

export const getSitemapPath = () => {
  let paths = [];
  const d = new Date();
  const lastMod =
    d.getFullYear() +
    "-" +
    (d.getMonth() < 10 ? "0" : "") +
    (d.getMonth() + 1) +
    "-" +
    (d.getDate() < 10 ? "0" : "") +
    d.getDate();

  routes.map(item => {
    paths.push({
      path: item.path,
      lastMod
    });

    if (item.children) {
      item.children.forEach(itemChild => {
        paths.push({
          path: item.path + "/" + itemChild.path,
          lastMod
        });
      });
    }
  });

  return paths;
};
