
<template>
	<div class="free-trial">
		<div class="container section">
			<div class="row justify-content-center">
				<div class="col-md-8 col-lg-6">
					<h3 class="title text-center color-white">
						{{ $t("s_day_free_trial") }}
					</h3>
				</div>
			</div>
			<div class="d-flex justify-content-center" style="margin-top: 20px">
				<a :href="toDashboardLogin" class="btn btn-main">
					{{ $t("get_it_now") }}
				</a>
			</div>
		</div>
	</div>
</template>

<script>
	import { keys, localS } from "@/utils";

	export default {
		name: "free-trial",
		data() {
			let urlQuerys = "";
			if (localS.get(keys.channel)) {
				urlQuerys = "?channel=" + localS.get(keys.channel);
			}
			return { toDashboardLogin: process.env.DASHBOARD_URL + urlQuerys };
		},
	};
</script>

<style scoped>
	.free-trial {
		background-image: url(@/assets/imgs/bg-calander2x.png);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}
</style>