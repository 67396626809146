<template>
	<div>
		<div class="container-fluid p-0 bg-dark-blue">
			<div class="container section">
				<div class="row align-items-center justify-content-center">
					<div class="col-4 color-white">
						<h3 class="title">共享线路模式</h3>
						<p class="color-gray mt-3">
							GT
							NET为您提供全球大多数国家的出口IP，实现异地联网，可满足跨境电商、网络游戏、看视频、直播的需求
						</p>
						<router-link to="/" class="btn btn-main banner-btn">
							开始使用
						</router-link>
					</div>
					<div class="col-6">
						<img src="@/assets/imgs/shared-banner2x.png" alt="shared" />
					</div>
				</div>
			</div>
		</div>
		<!--  -->
		<div class="advantage">
			<div class="container section">
				<div class="row justify-content-center">
					<div class="col-md-8 col-lg-6">
						<h3 class="title text-center">共享模式的优势</h3>
					</div>
				</div>
				<shared-advantage />
			</div>
		</div>
		<!--  -->
		<div class="container section bg-white">
			<div class="row justify-content-center">
				<div class="col-md-8 col-lg-6 text-center">
					<h3 class="title" style="margin-bottom: 20px">共享模式的报价</h3>
					<p class="subtitle">
						独享线路模式，您使用的每条线路都是定制的、独一无二的，线路价格跟您选择的出口地区、带宽和授权终端数有关。
					</p>
				</div>
			</div>
			<img src="@/assets/imgs/shared/quotation2x.png" alt="quotation" />
			<div class="row justify-content-center mt-3">
				<div class="col-10">
					<div class="bg-orange-light text-center py-3">
						<p class="color-dark-orange mb-0">
							如果需要获取报价，您可以注册账号，在后台查看；或者联系客服获取报价。
						</p>
					</div>
				</div>
			</div>
			<div class="d-flex justify-content-center" style="margin-top: 50px">
				<a :href="toDashboardRegister" class="btn btn-outline-main mx-2"
					>注册账号
				</a>
				<a href="" class="btn btn-main mx-2">联系客服</a>
			</div>
		</div>
		<!--  -->
		<free-trial />
		<!--  -->
	</div>
</template>

<script>
	import { keys, localS } from "@/utils";

	export default {
		name: "product-shared",
		components: {
			sharedAdvantage: () => import("@/pages/product/shared/s-advantage"),
			freeTrial: () => import("@/components/freetrial"),
		},
		data() {
			let urlQuerys = "";
			if (localS.get(keys.channel)) {
				urlQuerys = "?channel=" + localS.get(keys.channel);
			}
			return {
				toDashboardRegister:
					process.env.DASHBOARD_URL + "/register" + urlQuerys,
			};
		},
	};
</script>

<style scoped>
	.banner-btn {
		margin-top: 60px;
	}

	.advantage {
		background-image: url(@/assets/imgs/bg-privacy2x.png);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}
</style>