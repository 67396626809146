
<template>
	<nav
		id="header_nav"
		:class="`navbar navbar-expand-lg fixed-top user-select-none header ${
			isLight ? 'navbar-light' : 'navbar-dark'
		}`"
	>
		<div class="container">
			<router-link :to="home" class="navbar-brand">
				<img src="@/assets/imgs/logo_blue_title.png" alt="logo" />
			</router-link>
			<button
				class="navbar-toggler"
				type="button"
				data-toggle="collapse"
				data-target="#navbarNavDropdown"
				aria-controls="navbarNavDropdown"
				aria-expanded="false"
				aria-label="Toggle navigation"
			>
				<span class="navbar-toggler-icon"></span>
			</button>
			<div
				class="collapse navbar-collapse justify-content-center"
				id="navbarNavDropdown"
			>
				<ul class="navbar-nav">
					<li
						v-for="i in menus"
						:key="i.title"
						:class="`nav-item ${i.el === 'dropdown' ? 'dropdown' : ''} ${
							i.active
						}`"
					>
						<router-link
							v-if="i.el !== 'dropdown'"
							:class="`nav-link ${i.disabled || ''}`"
							:to="i.to"
						>
							{{ $t("header.menu." + i.title) }}
						</router-link>
						<template v-else>
							<a
								class="nav-link dropdown-toggle"
								href="#"
								:id="`navbarDropdownMenuLink${i.title}`"
								role="button"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								{{ $t("header.menu." + i.title) }}
							</a>
							<div
								class="dropdown-menu"
								:aria-labelledby="`navbarDropdownMenuLink${i.title}`"
							>
								<router-link
									v-for="j in i.child"
									:key="j.title"
									class="dropdown-item"
									:to="i.to + j.to"
								>
									{{ $t(`header.menu.${i.title}s.${j.title}`) }}
								</router-link>
							</div>
						</template>
					</li>
				</ul>
				<form class="form-inline header-btns">
					<!-- login -->
					<a
						:class="`btn ${
							isLight ? 'btn-text-darkgray' : 'btn-text-white'
						}`"
						style="min-width: unset"
						:href="toDashboardLogin"
					>
						{{ $t("header.login") }}
					</a>
					<!-- register -->
					<a
						class="btn btn-main py-1"
						style="min-width: 100px"
						:href="toDashboardRegister"
					>
						{{ $t("header.register") }}
					</a>
					<!-- language -->
					<button-group
						:color="isLight ? 'text-darkgray' : 'text-white'"
						groupClasses="dropdownleft"
						classes="btn-sm dropdown-toggle"
						:text="$t('header.language')"
						dropdownClasses="dropdown-menu-right"
						:dropdownItemsDefalut="
							langs.findIndex((item) => item.language === $i18n.locale)
						"
						:dropdownItems="langs"
						:onChange="changeLanguage"
					/>
				</form>
			</div>
		</div>
	</nav>
</template>

<script>
	import { cookie, keys, localS } from "@/utils";

	export default {
		name: "app-header",
		components: {
			buttonGroup: () => import("@/components/button/group"),
		},
		props: {
			isLight: Boolean,
		},
		data() {
			let langs = this.$i18n.availableLocales.map((language) => {
				return {
					type: "button",
					language,
					lang: this.$i18n.messages[language].header.lang,
					text: this.$i18n.messages[language].header.language,
				};
			});
			let urlQuerys = "";
			if (localS.get(keys.channel)) {
				urlQuerys = "?channel=" + localS.get(keys.channel);
			}
			return {
				home: keys.home,
				menus: [],
				toDashboardLogin: process.env.DASHBOARD_URL + urlQuerys,
				toDashboardRegister:
					process.env.DASHBOARD_URL + "/register" + urlQuerys,
				langs,
			};
		},
		watch: {
			$route: function () {
				this.renderMenu();
			},
		},
		methods: {
			renderMenu() {
				const menus = keys.headerMenu.map((i) => ({
					...i,
					active: i.to === window.location.pathname ? "active" : "",
				}));
				this.menus = menus;
			},
			changeLanguage(item) {
				cookie.setCookie(keys.lang, item.language);
				this.$i18n.locale = item.language;
				document.documentElement.setAttribute("lang", item.lang);
			},
		},
		created() {
			this.renderMenu();
		},
		mounted() {
			// get menus
			const navs = [
				...document.getElementsByClassName("navbar-nav")[0].children,
			];

			// mobile close menu event
			navs.forEach((e) => {
				e.children[0].addEventListener("click", function () {
					document
						.getElementById("navbarNavDropdown")
						.classList.remove("show");
				});
			});

			// window add event
			const nav = document.getElementById("header_nav");
			document.addEventListener("click", function (event) {
				var isClickInsideElement = nav.contains(event.target);
				if (!isClickInsideElement) {
					//Do something click is outside specified element
					document
						.getElementById("navbarNavDropdown")
						.classList.remove("show");
				}
			});
		},
	};
</script>

<style scoped>
	.header {
		min-height: 65px;
		background-color: #171b21ff;
	}
	.header.navbar-light {
		background-color: #ffffff;
	}
	.header .navbar-brand img {
		max-width: 130px;
	}
	.header .nav-item {
		margin-left: 13px;
		margin-right: 13px;
	}
	/* dark */
	.navbar-dark .navbar-nav .show > .nav-link,
	.navbar-dark .navbar-nav .active > .nav-link,
	.navbar-dark .navbar-nav .nav-link.show,
	.navbar-dark .navbar-nav .nav-link.active {
		color: #2368f0;
	}

	.navbar-dark .navbar-nav .nav-link {
		color: #ffffff;
	}

	.navbar-dark .navbar-nav .nav-link:hover,
	.navbar-dark .navbar-nav .nav-link:focus {
		color: #2368f0;
	}
	/* light */
	.navbar-light .navbar-nav .show > .nav-link,
	.navbar-light .navbar-nav .active > .nav-link,
	.navbar-light .navbar-nav .nav-link.show,
	.navbar-light .navbar-nav .nav-link.active {
		color: #2368f0;
	}

	.navbar-light .navbar-nav .nav-link {
		color: #4b4b4bff;
	}

	.navbar-light .navbar-nav .nav-link:hover,
	.navbar-light .navbar-nav .nav-link:focus {
		color: #2368f0;
	}

	.header-btns > *:not(:last-child) {
		margin-right: 15px;
	}

	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.header-btns {
			border-top: #282828ff solid 1px;
			justify-content: space-around;
			margin-top: 10px;
			padding-bottom: 5px;
		}
		.header-btns > * {
			margin-top: 15px;
		}
	}
	/* Small devices (landscape phones, 576px and up) */
	@media (min-width: 576px) and (max-width: 767.98px) {
		.header-btns {
			border-top: #282828ff solid 1px;
			justify-content: space-around;
			margin-top: 10px;
			padding-bottom: 5px;
		}
		.header-btns > * {
			margin-top: 15px;
		}
	}
	/* Medium devices (tablets, 768px and up) */
	@media (min-width: 768px) and (max-width: 991.98px) {
		.header-btns {
			border-top: #282828ff solid 1px;
			justify-content: space-around;
			margin-top: 10px;
			padding-bottom: 5px;
		}
		.header-btns > * {
			margin-top: 15px;
		}
	}
	/* Large devices (desktops, 992px and up) */
	@media (min-width: 992px) and (max-width: 1199.98px) {
		.header .navbar-brand img {
			max-width: 100px;
		}
		.header .nav-item {
			margin-left: 5px;
			margin-right: 5px;
		}
		.header-btns {
			margin-left: 10px;
		}
		.header-btns > *:not(:last-child) {
			margin-right: 10px;
		}
	}
	/* Extra large devices (large desktops, 1200px and up) */
	@media (min-width: 1200px) {
		.header-btns {
			margin-left: 60px;
		}
	}
</style>