
<template>
	<footer class="py-5 user-select-none">
		<div class="container">
			<div class="row">
				<div
					v-for="(i, idx) in links"
					:key="idx"
					class="col-6 col-sm-4 col-md-3"
				>
					<ul class="list-unstyled">
						<li v-for="j in i.child" :key="j.text">
							<router-link
								:to="j.to"
								:class="`text-decoration-none ${j.disabled || ''}`"
							>
								{{ $t("footer." + j.text) }}
							</router-link>
						</li>
					</ul>
				</div>

				<div class="col-3">
					<img
						src="@/assets/imgs/logo_horizontal_blue_title2x.png"
						alt="GT NET logo"
						class="mt-3"
						style="max-width: 80px"
					/>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
	import { keys } from "@/utils";

	export default {
		name: "app-footer",
		data() {
			return {
				links: keys.footerMenu,
			};
		},
	};
</script>

<style scoped>
	footer {
		background-color: #0f1620ff;
	}
	a {
		color: #989898;
		line-height: 2rem;
	}
</style>