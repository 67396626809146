<template>
	<div>
		<div class="container-fluid slider-banner p-0">
			<slider
				id="section1"
				:pages="sliderPages"
				:item="() => import('@/pages/home/sliderItem')"
			/>
		</div>
		<!--  -->
		<div class="container section">
			<div class="row justify-content-center">
				<div class="col-md-8 col-lg-6">
					<h3 class="title text-center">
						{{ $t("home.high_performance_title") }}
					</h3>
				</div>
			</div>
			<div class="row align-items-center" style="margin-top: 50px">
				<div class="col-md-6">
					<img
						src="@/assets/imgs/desktop_mobile2x.png"
						alt="desktop and mobile"
					/>
				</div>
				<div class="col-md-6">
					<h5 class="color-dark-gray">{{ $t("home.hp_sec1_title") }}</h5>
					<p class="color-gray">
						{{ $t("home.hp_sec1_desc") }}
					</p>
					<router-link :to="toServerLocation" class="btn btn-main">
						{{ $t("view_server") }}
					</router-link>
				</div>
			</div>
			<div
				class="row align-items-center flex-wrap-reverse"
				style="margin-top: 50px"
			>
				<div class="col-md-6">
					<h5 class="color-dark-gray">{{ $t("home.hp_sec2_title") }}</h5>
					<p class="color-gray">
						{{ $t("home.hp_sec2_desc") }}
					</p>
					<router-link :to="toServerLocation" class="btn btn-main">
						{{ $t("view_server") }}
					</router-link>
				</div>
				<div class="col-md-6">
					<img
						src="@/assets/imgs/browse2x.png"
						alt="browse websites"
						class="mb-5 mb-md-0"
					/>
				</div>
			</div>
		</div>
		<!--  -->
		<div class="section-enterprise">
			<div class="container section">
				<div class="row justify-content-center">
					<div class="col-md-8 col-lg-6">
						<h3 class="title text-center">
							{{ $t("home.private_server_title") }}
						</h3>
					</div>
				</div>
				<div class="row" style="margin-top: 50px">
					<div class="col-md-6 mb-4">
						<div class="bg-white shadow p-3 enterprise-card">
							<div class="bg-gradient2">
								<h6 class="title text-center">
									{{ $t("home.ps_sec1_title") }}
								</h6>
							</div>
							<div class="text-center px-3 imgBox">
								<img
									:src="
										require(`@/assets/imgs/ee_gt_net_${$i18n.locale}2x.png`)
									"
									:alt="$t('home.ps_sec1_title')"
								/>
							</div>
							<div class="bg-main-light p-2 contentBox">
								{{ $t("home.ps_sec1_desc") }}
							</div>
						</div>
					</div>
					<div class="col-md-6 mb-4">
						<div class="bg-white shadow p-3 enterprise-card">
							<div class="bg-gradient3">
								<h6 class="title text-center">
									{{ $t("home.ps_sec2_title") }}
								</h6>
							</div>
							<div class="text-center px-3 imgBox">
								<img
									src="@/assets/imgs/personal_vpn2x.png"
									:alt="$t('home.ps_sec2_title')"
								/>
							</div>
							<div class="bg-orange-lighter p-2 contentBox">
								{{ $t("home.ps_sec2_desc") }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--  -->
		<div class="section-privacy">
			<div class="container section">
				<div class="row justify-content-center" style="margin-bottom: 30px">
					<div class="col-md-8 col-lg-6">
						<h3 class="title text-center">
							{{ $t("home.privacy_data_title") }}
						</h3>
					</div>
				</div>
				<div class="privacy-group">
					<div class="d-flex justify-content-center">
						<div class="privacy-encryp text-center color-white">
							<h5>{{ $t("home.encryption_algorithm") }}</h5>
							<p>{{ $t("home.encryption_algorithm_desc") }}</p>
						</div>
					</div>
					<div class="row privacy-row">
						<div class="col-12 col-sm-3 position-relative p-0">
							<div class="w-100 text-center privacy-list">
								<div>
									<img src="@/assets/imgs/hacker2x.png" alt="img" />
								</div>
								<p class="color-dark-blue">
									{{ $t("home.pd_text1") }}
								</p>
							</div>
						</div>
						<div class="col-12 col-sm-3 position-relative text-center">
							<div class="w-100 text-center privacy-list">
								<div>
									<img src="@/assets/imgs/browser2x.png" alt="img" />
								</div>
								<p class="color-dark-blue">
									{{ $t("home.pd_text2") }}
								</p>
							</div>
						</div>
						<div class="col-12 col-sm-3 position-relative text-center">
							<div class="w-100 text-center privacy-list">
								<div>
									<img src="@/assets/imgs/network2x.png" alt="img" />
								</div>
								<p class="color-dark-blue">
									{{ $t("home.pd_text3") }}
								</p>
							</div>
						</div>
						<div class="col-12 col-sm-3 position-relative p-0">
							<div class="w-100 text-center privacy-list">
								<div>
									<img src="@/assets/imgs/ads2x.png" alt="img" />
								</div>
								<p class="color-dark-blue">
									{{ $t("home.pd_text4") }}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--  -->
		<div class="container section">
			<div class="row justify-content-center" style="margin-bottom: 50px">
				<div class="col-md-8 col-lg-6">
					<h3 class="title text-center">
						{{ $t("home.func_advantage_title") }}
					</h3>
				</div>
			</div>
			<div class="row">
				<div
					v-for="(i, idx) in vpnFuncs"
					:key="i.title"
					class="col-md-6 col-lg-4 mb-4"
				>
					<div
						class="card functions px-5 py-4"
						style="height: 100%"
						@mouseover="getVPNFuncs(idx)"
						@mouseleave="getVPNFuncs"
					>
						<div class="text-center mb-3" style="height: 120px">
							<img
								:src="i.imgUrl"
								:alt="i.imgAlt"
								style="width: auto; max-height: 120px"
							/>
						</div>
						<h6 class="title text-center">{{ i.title }}</h6>
						<!--  -->
						<ul
							v-if="i.list"
							class="color-gray"
							style="padding-inline-start: 20px"
						>
							<li v-for="j in i.list" :key="j">{{ j }}</li>
						</ul>
						<p v-else class="color-gray mb-0">{{ i.desc }}</p>
					</div>
				</div>
			</div>
		</div>
		<!--  -->
		<div class="service">
			<div class="container section">
				<div class="row justify-content-center" style="margin-bottom: 50px">
					<div class="col-md-8 col-lg-6">
						<h3 class="title text-center">{{ $t("home.pledge") }}</h3>
					</div>
				</div>
				<div class="row text-center">
					<div class="col-md-4 mb-3">
						<img
							class="service-icon"
							src="@/assets/imgs/service/one-on-one2x.png"
							:alt="$t('home.pledge_sec1')"
						/>
						<p>{{ $t("home.pledge_sec1") }}</p>
					</div>
					<div class="col-md-4 mb-3">
						<img
							class="service-icon"
							src="@/assets/imgs/service/24hr2x.png"
							:alt="$t('home.pledge_sec2')"
						/>
						<p>{{ $t("home.pledge_sec2") }}</p>
					</div>
					<div class="col-md-4 mb-3">
						<img
							class="service-icon"
							src="@/assets/imgs/service/free2x.png"
							:alt="$t('home.pledge_sec3')"
						/>
						<p>{{ $t("home.pledge_sec3") }}</p>
					</div>
				</div>
			</div>
		</div>
		<!--  -->
	</div>
</template>

<script>
	import { keys, localS } from "@/utils";

	export default {
		name: "home",
		components: {
			slider: () => import("@/components/slider"),
		},
		data() {
			let urlQuerys = "";
			if (localS.get(keys.channel)) {
				urlQuerys = "?channel=" + localS.get(keys.channel);
			}
			return {
				sliderPages: [
					{
						title: this.$t("home.banner_title1"),
						desc: this.$t("home.banner_desc1"),
						aText: this.$t("start_using"),
						href: process.env.DASHBOARD_URL + urlQuerys,
						bgImgUrl: require("@/assets/imgs/gt_net_banner2x.png"),
						bgImgMobileUrl: require("@/assets/imgs/gt_net_banner_mobile2x.png"),
						bgImgAlt: "GT NET site to site",
					},
					// {
					// 	title: "",
					// 	desc: "",
					// 	to: "",
					// 	imgUrl: "",
					// 	imgAlt: "",
					// },
				],
				toServerLocation: keys.serverLocation,
				vpnFuncs: [],
			};
		},
		methods: {
			getVPNFuncs(index) {
				const defList = [
					{
						title: this.$t("home.fa_sec1_title"),
						list: [
							this.$t("home.fa_sec1_list1"),
							this.$t("home.fa_sec1_list2"),
						],
					},
					{
						title: this.$t("home.fa_sec2_title"),
						list: [
							this.$t("home.fa_sec2_list1"),
							this.$t("home.fa_sec2_list2"),
						],
					},
					{
						title: this.$t("home.fa_sec3_title"),
						desc: this.$t("home.fa_sec3_list1"),
					},
					{
						title: this.$t("home.fa_sec4_title"),
						desc: this.$t("home.fa_sec4_list1"),
					},
					{
						title: this.$t("home.fa_sec5_title"),
						desc: this.$t("home.fa_sec5_list1"),
					},
					{
						title: this.$t("home.fa_sec6_title"),
						list: [
							this.$t("home.fa_sec6_list1"),
							this.$t("home.fa_sec6_list2"),
						],
					},
					{
						title: this.$t("home.fa_sec7_title"),
						desc: this.$t("home.fa_sec7_list1"),
					},
					{
						title: this.$t("home.fa_sec8_title"),
						list: [
							this.$t("home.fa_sec8_list1"),
							this.$t("home.fa_sec8_list2"),
							this.$t("home.fa_sec8_list3"),
						],
					},
					{
						title: this.$t("home.fa_sec9_title"),
						list: [
							this.$t("home.fa_sec9_list1"),
							this.$t("home.fa_sec9_list2"),
						],
					},
				];

				const vpnFuncs = defList.map((i, idx) => ({
					...i,
					imgUrl: require(`@/assets/imgs/funcs/${idx + 1}_${
						index !== undefined && idx === index ? "blue" : "gray"
					}2x.png`),
					imgAlt: i.title,
				}));

				this.vpnFuncs = vpnFuncs;
			},
		},
		created() {
			this.getVPNFuncs();
		},
	};
</script>

<style scoped>
	.slider-banner {
		margin-top: -50px;
	}
	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.slider-banner {
			margin-top: 0px;
		}
	}
	/* Small devices (landscape phones, 576px and up) */
	@media (min-width: 576px) and (max-width: 767.98px) {
		.slider-banner {
			margin-top: 0px;
		}
	}
	/* Medium devices (tablets, 768px and up) */
	@media (min-width: 768px) and (max-width: 991.98px) {
		.slider-banner {
			margin-top: -15px;
		}
	}
	/* Large devices (desktops, 992px and up) */
	@media (min-width: 992px) and (max-width: 1199.98px) {
		.slider-banner {
			margin-top: 0px;
		}
	}

	.section-enterprise {
		background-image: url(@/assets/imgs/bg-enterprise2x.png);
		background-position: top center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	.enterprise-card .imgBox {
		height: 310px;
	}
	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.enterprise-card .imgBox {
			height: unset;
		}
		.enterprise-card .contentBox {
			min-height: unset;
		}
	}
	/* Medium devices (tablets, 768px and up) */
	@media (min-width: 768px) and (max-width: 991.98px) {
		.enterprise-card .imgBox {
			height: 170px;
		}
		.enterprise-card .contentBox {
			min-height: 140px;
		}
	}

	.section-privacy {
		background-image: url(@/assets/imgs/bg-privacy2x.png);
		background-position: top center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	.privacy-group {
		background-image: url(@/assets/imgs/bg-u2x.png);
		background-position: top -50px center;
		background-repeat: no-repeat;
		background-size: contain;
		height: 550px;
	}
	.privacy-group .privacy-row {
		margin-top: 40px;
	}
	.privacy-encryp {
		background-image: url(@/assets/imgs/privacy2x.png);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		width: 200px;
		height: 240px;
	}
	.privacy-encryp h5 {
		font-size: 1.06rem;
		font-weight: bold;
		margin-top: 85px;
		margin-left: 40px;
		margin-right: 40px;
	}
	.privacy-encryp p {
		font-size: 0.8rem;
		font-weight: bold;
		line-height: 1rem;
		margin-top: 20px;
		margin-left: 25px;
		margin-right: 25px;
	}
	.privacy-list {
		position: absolute;
	}
	.privacy-list img {
		width: 130px;
		margin-bottom: -20px;
	}
	.privacy-list p {
		font-weight: bold;
		color: #193950ff;
		font-size: 14px;
		max-width: 145px;
		margin-left: auto;
		margin-right: auto;
	}
	.privacy-group div.col-sm-3:nth-child(1) .privacy-list {
		bottom: 0px;
		right: -170px;
	}
	.privacy-group div.col-sm-3:nth-child(2) .privacy-list {
		right: -35px;
	}
	.privacy-group div.col-sm-3:nth-child(3) .privacy-list {
		left: -35px;
	}
	.privacy-group div.col-sm-3:nth-child(4) .privacy-list {
		bottom: 0px;
		left: -170px;
	}
	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.privacy-group {
			height: unset;
			background-image: unset;
		}
		.privacy-list {
			position: unset;
		}
		.privacy-group div.col-sm-3:nth-child(1) .privacy-list {
			bottom: 0px;
			right: 0px;
		}
		.privacy-group div.col-sm-3:nth-child(2) .privacy-list {
			right: 0px;
		}
		.privacy-group div.col-sm-3:nth-child(3) .privacy-list {
			left: 0px;
		}
		.privacy-group div.col-sm-3:nth-child(4) .privacy-list {
			bottom: 0px;
			left: 0px;
		}
	}
	/* Small devices (landscape phones, 576px and up) */
	@media (min-width: 576px) and (max-width: 767.98px) {
		.privacy-group {
			background-position: top 15px center;
			height: 400px;
		}
		.privacy-group .privacy-row {
			margin-top: -10px;
		}
		.privacy-group div.col-sm-3:nth-child(1) .privacy-list {
			bottom: 0px;
			right: -30px;
		}
		.privacy-group div.col-sm-3:nth-child(2) .privacy-list {
			right: 10px;
		}
		.privacy-group div.col-sm-3:nth-child(3) .privacy-list {
			left: 10px;
		}
		.privacy-group div.col-sm-3:nth-child(4) .privacy-list {
			bottom: 0px;
			left: -30px;
		}
	}
	/* Medium devices (tablets, 768px and up) */
	@media (min-width: 768px) and (max-width: 991.98px) {
		.privacy-group {
			height: 520px;
		}
		.privacy-group div.col-sm-3:nth-child(1) .privacy-list {
			bottom: 0px;
			right: -50px;
		}
		.privacy-group div.col-sm-3:nth-child(2) .privacy-list {
			right: 0px;
		}
		.privacy-group div.col-sm-3:nth-child(3) .privacy-list {
			left: 0px;
		}
		.privacy-group div.col-sm-3:nth-child(4) .privacy-list {
			bottom: 0px;
			left: -50px;
		}
	}
	/* Large devices (desktops, 992px and up) */
	@media (min-width: 992px) and (max-width: 1199.98px) {
		.privacy-group div.col-md-3:nth-child(1) .privacy-list {
			bottom: 0px;
			right: -120px;
		}
		.privacy-group div.col-md-3:nth-child(2) .privacy-list {
			right: -25px;
		}
		.privacy-group div.col-md-3:nth-child(3) .privacy-list {
			left: -25px;
		}
		.privacy-group div.col-md-3:nth-child(4) .privacy-list {
			bottom: 0px;
			left: -120px;
		}
	}

	.functions {
		background: rgb(231, 236, 245);
		background: linear-gradient(
			180deg,
			rgba(231, 236, 245, 1) 0%,
			rgba(255, 255, 255, 0) 100%
		);
	}

	.service {
		background-image: url(@/assets/imgs/service/bg-service2x.png);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		color: #ffffff;
	}
	.service .service-icon {
		max-width: 90px;
		margin-bottom: 10px;
	}
</style>