<template>
	<div :id="'slider_' + id" class="carousel" data-ride="carousel">
		<ol class="carousel-indicators">
			<li
				v-for="idx in pages.length"
				:key="idx - 1"
				:data-target="'#slider_' + id"
				:data-slide-to="idx - 1"
				:class="idx - 1 === 0 ? 'active' : ''"
			/>
		</ol>
		<div class="carousel-inner">
			<template v-if="item">
				<component
					v-for="(i, idx) in pages"
					:key="idx"
					:is="item"
					:active="idx === 0"
					:data="i"
					:index="idx"
				/>
			</template>
			<div
				v-else
				v-for="(i, idx) in pages"
				:key="idx"
				:class="`carousel-item ${idx === 0 ? 'active' : ''}`"
			>
				<img
					:src="i.imgUrl"
					class="d-block w-100"
					:alt="i.imgAlt || 'background image'"
				/>
			</div>
		</div>
		<a
			v-if="control"
			class="carousel-control-prev"
			:href="'#slider_' + id"
			role="button"
			data-slide="prev"
		>
			<span class="carousel-control-prev-icon" aria-hidden="true"></span>
			<span class="sr-only">Previous</span>
		</a>
		<a
			v-if="control"
			class="carousel-control-next"
			:href="'#slider_' + id"
			role="button"
			data-slide="next"
		>
			<span class="carousel-control-next-icon" aria-hidden="true"></span>
			<span class="sr-only">Next</span>
		</a>
	</div>
</template>

<script>
	export default {
		name: "slider",
		props: {
			id: {
				type: String,
				default: "",
			},
			pages: {
				type: Array,
				default: () => [],
			},
			item: Function,
			control: {
				type: Boolean,
				default: false,
			},
			interval: Number,
			autoPlay: Boolean,
		},
		mounted() {
			$("#slider_" + this.id).carousel({
				interval: this.autoPlay ? this.interval || 5000 : false,
			});
		},
	};
</script>
