<template>
	<div class="d-flex flex-wrap bg-white mt-4" style="height: 650px">
		<div class="sidenav">
			<button class="btn sidenav-btn" @click="dropdown">
				{{ sidenavList[currentNavPage].navText }}
			</button>
			<div :class="`sidenav-list ${menuShow ? 'show' : ''}`">
				<a
					v-for="(i, idx) in sidenavList"
					:key="i.navText"
					href="javascript:void(0);"
					:class="idx === currentNavPage ? 'active' : ''"
					@click="changeNavPage(idx)"
				>
					{{ i.navText }}
				</a>
			</div>
		</div>
		<div class="page-content overflow-auto px-5 py-4">
			<h5 class="color-dark-gray font-weight-bold">
				{{ sidenavList[currentNavPage].title }}
			</h5>
			<template v-if="Array.isArray(sidenavList[currentNavPage].desc)">
				<ul class="list-style1">
					<li
						v-for="i in sidenavList[currentNavPage].desc"
						:key="i"
						v-html="i"
					>
						{{ i }}
					</li>
				</ul>
			</template>
			<img
				:src="sidenavList[currentNavPage].imgUrl"
				:alt="sidenavList[currentNavPage].imgAlt"
			/>
		</div>
	</div>
</template>

<script>
	export default {
		name: "exclusive-advantage",
		data() {
			return {
				sidenavList: [
					{
						navText: this.$t("product.exclusive.a1_nav"),
						title: this.$t("product.exclusive.a1_title"),
						desc: [
							this.$t("product.exclusive.a1_list1"),
							this.$t("product.exclusive.a1_list2"),
						],
					},
					{
						navText: this.$t("product.exclusive.a2_nav"),
						title: this.$t("product.exclusive.a2_title"),
						desc: [this.$t("product.exclusive.a2_list1")],
					},
					{
						navText: this.$t("product.exclusive.a3_nav"),
						title: this.$t("product.exclusive.a3_title"),
						desc: [
							this.$t("product.exclusive.a3_list1"),
							this.$t("product.exclusive.a3_list2"),
							this.$t("product.exclusive.a3_list3"),
						],
					},
					{
						navText: this.$t("product.exclusive.a4_nav"),
						title: this.$t("product.exclusive.a4_title"),
						desc: [this.$t("product.exclusive.a4_list1")],
					},
					{
						navText: this.$t("product.exclusive.a5_nav"),
						title: this.$t("product.exclusive.a5_title"),
						desc: [this.$t("product.exclusive.a5_list1")],
					},
					{
						navText: this.$t("product.exclusive.a6_nav"),
						title: this.$t("product.exclusive.a6_title"),
						desc: [this.$t("product.exclusive.a6_list1")],
					},
					{
						navText: this.$t("product.exclusive.a7_nav"),
						title: this.$t("product.exclusive.a7_title"),
						desc: [
							this.$t("product.exclusive.a7_list1"),
							this.$t("product.exclusive.a7_list2"),
							this.$t("product.exclusive.a7_list3"),
						],
					},
					{
						navText: this.$t("product.exclusive.a8_nav"),
						title: this.$t("product.exclusive.a8_title"),
						desc: [this.$t("product.exclusive.a8_list1")],
					},
				],
				currentNavPage: 0,
				menuShow: false,
			};
		},
		methods: {
			changeNavPage(idx) {
				this.currentNavPage = idx;
				this.menuShow = !this.menuShow;
			},
			dropdown() {
				this.menuShow = !this.menuShow;
			},
		},
		created() {
			const sidenavList = this.sidenavList.map((i, idx) => {
				let imgUrl = "";
				try {
					imgUrl = require(`@/assets/imgs/exclusive/${idx + 1}2x.png`);
				} catch (err) {}

				return {
					...i,
					imgUrl,
					imgAlt: i.title,
				};
			});
			this.sidenavList = sidenavList;
		},
	};
</script>

<style scoped>
	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.sidenav {
			width: 100%;
			position: relative;
			display: inline-block;
		}
	}
	/* Small devices (landscape phones, 576px and up) */
	@media (min-width: 576px) and (max-width: 767.98px) {
		.sidenav {
			width: 100%;
			position: relative;
			display: inline-block;
		}
	}

	/* side nav button */
	.sidenav-btn {
		display: none;
		/* btn style */
		color: #fff;
		background-color: #1f2b33ff;
		padding: 10px 20px;
		text-align: left;
		/* arrow */
		position: relative;
	}
	.sidenav-btn::after {
		position: absolute;
		right: 15px;
		top: 20px;
		display: inline-block;
		margin-right: 0.255em;
		vertical-align: 0.255em;
		content: "";
		border-left: 0.3em solid transparent;
		border-top: 0.3em solid;
		border-right: 0.3em solid transparent;
	}
	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.sidenav-btn {
			display: block;
			width: 100%;
		}
	}
	/* Small devices (landscape phones, 576px and up) */
	@media (min-width: 576px) and (max-width: 767.98px) {
		.sidenav-btn {
			display: block;
			width: 100%;
		}
	}

	/* side nav list */
	.sidenav-list {
		height: 100%;
		/* 100% Full-height */
		min-width: 250px;
		/* 0 width - change this with JavaScript */
		overflow-x: hidden;
		/* Disable horizontal scroll */
		transition: 0.5s;
		/* 0.5 second transition effect to slide in the sidenav */
		background-color: #1f2b33ff;
	}
	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.sidenav-list {
			min-width: unset;
			width: 100%;
			height: 0;
			display: block;
			position: absolute;
			min-width: 200px;
			overflow: auto;
			box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
			z-index: 1;
		}
		/* for dropdown */
		.show {
			height: 300px;
			transition: 0.25s;
		}
	}
	/* Small devices (landscape phones, 576px and up) */
	@media (min-width: 576px) and (max-width: 767.98px) {
		.sidenav-list {
			min-width: unset;
			width: 100%;
			height: 0;
			display: block;
			position: absolute;
			min-width: 200px;
			overflow: auto;
			box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
			z-index: 1;
		}
		/* for dropdown */
		.show {
			height: 300px;
			transition: 0.25s;
		}
	}

	/* The navigation menu links */

	.sidenav-list a {
		color: #a6a5a5ff;
		text-decoration: none;
		font-size: 16px;
		display: block;
		transition: 0.3s;
		padding: 25px 30px;
	}
	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.sidenav-list a {
			padding: 12px 16px;
		}
	}
	/* Small devices (landscape phones, 576px and up) */
	@media (min-width: 576px) and (max-width: 767.98px) {
		.sidenav-list a {
			padding: 12px 16px;
		}
	}

	.sidenav-list a.active,
	.sidenav-list a:hover {
		color: #ffffff;
		background: rgb(39, 108, 234);
		background: linear-gradient(
			90deg,
			rgba(39, 108, 234, 1) 0%,
			rgba(255, 255, 255, 0) 100%
		);
	}

	.sidenav-list a.active:hover {
		color: #ffffff;
	}

	.page-content {
		flex: 1;
		height: 100%;
	}
	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.page-content {
			width: 100%;
		}
	}
	/* Small devices (landscape phones, 576px and up) */
	@media (min-width: 576px) and (max-width: 767.98px) {
		.page-content {
			width: 100%;
		}
	}

	.page-content li {
		line-height: 38px;
	}
</style>